<template>
  <div>
    <div v-show="!showRecord">
      <a-spin :spinning="loading">
        <a-form ref="form" :model="formState" name="form" @finish="onSearch">
          <a-row>
            <a-form-item
              class="ui-form__item"
              name="organizationId"
              label="影院组织"
            >
              <a-select
                placeholder="请选择"
                v-model:value="formState.organizationId"
                style="width: 250px"
                @change="getAllCinemaList"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :vlaue="item.id"
                  :key="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
              <a-select
                placeholder="请选择"
                v-model:value="formState.cinemaId"
                style="width: 250px"
                @change="getCardPolicyList"
              >
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option
                  v-for="item in cinemaAllList"
                  :vlaue="item.id"
                  :key="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-row>
          <a-row v-show="showAll">
            <a-form-item
              label="影城会员卡号"
              name="cardNumber"
              class="ui-form__item"
            >
              <a-input
                v-model:value="formState.cardNumber"
                placeholder="请输入影城会员卡号"
              ></a-input>
            </a-form-item>
            <a-form-item
              label="会员昵称"
              name="memberName"
              class="ui-form__item"
            >
              <a-input
                v-model:value="formState.memberName"
                placeholder="请输入会员昵称"
              ></a-input>
            </a-form-item>

            <a-form-item
              label="会员手机"
              name="memberPhone"
              class="ui-form__item"
            >
              <a-input
                v-model:value="formState.memberPhone"
                placeholder="请输入会员手机"
              ></a-input>
            </a-form-item>

            <a-form-item label="绑定时间" class="ui-form__item">
              <a-range-picker v-model:value="time"></a-range-picker>
            </a-form-item>
          </a-row>

          <a-row>
            <a-col :span="18">
              <a-button
                v-permission="['coupon_memberCard_bind']"
                type="primary"
                @click="onBindMemberCard"
                >绑卡</a-button
              >
			  <a-button v-permission="['member_manage_info_card_sync']" style="margin-left: 20px" :disabled="!formState.cinemaId" type="primary" @click="onSyncBatch">
				  同步影院下所有卡数据
			  </a-button>
              <a style="margin-left: 20px" @click="showAll = !showAll">
                {{ showAll ? "收起" : "展开" }}
                <Icon v-show="!showAll" icon="DownOutlined"></Icon>
                <Icon v-show="showAll" icon="UpOutlined"></Icon>
              </a>
            </a-col>
            <a-col :span="6" style="text-align: right">
              <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
                >搜索</a-button
              >
              <a-button @click="reset">重置</a-button>
            </a-col>
          </a-row>
        </a-form>

        <div style="margin-top: 20px">
          <a-table
            :pagination="pagination"
            :columns="columns"
            :dataSource="list"
            rowKey="id"
            :scroll="{ x: 1800 }"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'userInfo'">
                <div>会员昵称：{{ record.memberName || "-" }}</div>
                <div>会员手机：{{ record.memberPhone || "-" }}</div>
              </template>
              <template v-if="column.key === 'isDisabled'">
                {{ record.isDisabled ? "已禁用" : "已启用" }}
              </template>
              <template v-else-if="column.key === 'createTime'">
                <div>{{ transDateTime(record.createTime) }}</div>
              </template>
			  <template v-else-if="column.key === 'expireDate'">
				  <div>{{ transDateTime(record.expireDate, 1) }}</div>
			  </template>
			  <template v-else-if="column.key === 'status'">
				  <div>{{['正常', '过期', '手动停用', '信用停用', '启用审核中', '未认证', '退卡', '已回收'][record.status - 1]}}</div>
			  </template>
              <template v-else-if="column.key === 'action'">
                <a-dropdown :trigger="['click', 'hover']">
                  <a-button @click.prevent
                    >操作 <Icon icon="DownOutlined"></Icon>
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <div
                        v-permission="['coupon_memberCard_info_disabled']"
                        v-if="record.isDisabled === 0"
                        @click="onDisabled(record)"
                      >
                        <a-menu-item> 禁用 </a-menu-item>
                      </div>
                      <div
                        v-permission="['coupon_memberCard_info_open']"
                        v-else
                        @click="onDisabled(record)"
                      >
                        <a-menu-item> 启用 </a-menu-item>
                      </div>
                      <div
                        v-permission="['member_manage_info_card_detail']"
                        @click="onMemberCardDetails(record)"
                      >
                        <a-menu-item> 影城会员卡详情 </a-menu-item>
                      </div>
					  <div v-permission="['member_manage_info_card_sync']" @click="onSync(record)">
						  <a-menu-item>同步卡数据</a-menu-item>
					  </div>
                      <div v-permission="['coupon_memberCard_info_modify']" @click="onModifyBalance(record)">
						<a-menu-item>
							充值金额
						</a-menu-item>
					  </div>
					  <div v-permission="['coupon_memberCard_info_unbind']" @click="onUnbind(record)">
						  <a-menu-item>解绑</a-menu-item>
					  </div>
                    </a-menu>
                  </template>
                </a-dropdown>
              </template>
            </template>
          </a-table>
        </div>

			<a-modal title="修改线上会员卡余额" width="600px" v-model:visible="showModal" @cancel="onModifyCancel"
				@ok="onModifyOk">
				<a-spin :spinning="loading">
					<a-form ref="modifyForm" name="modifyForm" :model="modelRef" :labelCol="{ span: 6 }"
						:wrapperCol="{ span: 14 }">
						<a-form-item label="现有余额">
							{{ modifyItem.balance || 0 }} 元
						</a-form-item>

						<a-form-item label="充值金额" name="balance" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input-number v-model:value="modelRef.balance" :min="'-' + modifyItem.balance" :precision="2" placeholder="请输入">
							</a-input-number> 元
						</a-form-item>
						
						<a-form-item label="充值后金额">
							{{ (modifyItem.balance + (modelRef.balance || 0)).toFixed(2) }} 元
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
      </a-spin>
    </div>
    <Recharge v-if="showRecord" :cardInfo="cardInfo" @back="onBack"></Recharge>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import exportComponent from "@/components/exportReport/exportReport.vue";
import Recharge from "./recharge.vue";
import {
  getOrganizationList,
  getCinemaList,
} from "@/service/modules/cinema.js";
import {
  getCardPolicyList,
  getCardInfoList,
  pullOnShelvesCardInfo,
  pullOffShelvesCardInfo,
  modifyBalance,
  syncCardInfo,
  cardUnbinding
} from "@/service/modules/coupon.js";
export default {
  components: {
    Icon,
    exportComponent,
    Recharge,
  },
  data() {
    return {
      loading: false,
      showAll: false,
      organizationList: [],
      cinemaAllList: [],
      cardAllList: [],
      formState: {
        type: 4,
        //organizationId: 0,
        cinemaId: 0,
        cardId: 0,
        status: 0,
      },
      showRecord: false,
      id: 0,
      movieId: 0,
      cardInfo: {},
      showModal: false,
      modifyItem: {},
      modelRef: {},
      selectedRowKeys: [],
      selectedListData: [],
      time: [],
      list: [],
      columns: [
        {
          title: "影院",
          dataIndex: "cinemaName",
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "余额",
          dataIndex: "balance",
          width: 120,
        },
        {
          title: "会员信息",
          key: "userInfo",
        },
        {
          title: "绑定时间",
          key: "createTime",
          width: 200,
        }, {
			title: '卡有效期至',
			dataIndex: 'expireDate',
			key: 'expireDate'
		}, {
			title: '卡状态',
			dataIndex: 'status',
			key: 'status'
		}, {
          title: "状态",
          width: 100,
          key: "isDisabled",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 160,
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ["10", "20", "50", "100", "500"],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        },
      },
    };
  },
  created() {
    this.getOrganizationList();
    //this.onSearch();
  },
  methods: {
    // 绑定会员卡
    onBindMemberCard() {
      this.$router.push("/coupon/memberCard/bind");
    },
    onBack() {
      this.showModal = false;
      this.showRecord = false;
    },
    onModifyBalance(record) {
				this.modifyItem = record;
				this.modelRef = {};
				this.showModal = true;
			},
    onSearch() {
      this.pagination.current = 1;
      // //this.pagination.pageSize = 10;
      this.selectedListData = [];
      this.selectedRowKeys = [];
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      if (this.searchData.isNeverExpire === -1) {
        this.searchData.isNeverExpire = undefined;
        this.time = [];
      }
      if (this.searchData.isNeverExpire === 1) {
        this.time = [];
      }
      this.searchData.organizationId = this.searchData.organizationId
        ? this.searchData.organizationId
        : undefined;
      this.searchData.cinemaId = this.searchData.cinemaId
        ? this.searchData.cinemaId
        : undefined;
      this.searchData.status = this.searchData.status
        ? this.searchData.status
        : undefined;
      this.searchData.cardId = this.searchData.cardId
        ? this.searchData.cardId
        : undefined;
      if (this.time && this.time.length) {
        this.searchData.startCreateTime =
          this.time[0].startOf("day").valueOf() / 1000;
        this.searchData.endCreateTime =
          this.time[1].endOf("day").valueOf() / 1000;
      }
      this.getData();
    },
    reset() {
      this.time = [];
      this.$refs.form.resetFields();
      if (this.organizationList.length) {
        this.formState.organizationId = this.organizationList[0].id;
        this.getAllCinemaList(this.formState.organizationId);
      }
      this.onSearch();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getCardInfoList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          type: 4,
          ...this.searchData,
        });
        this.loading = false;
        if (ret.code === 200) {
          this.pagination.total = ret.data.totalCount;
          this.list = ret.data.list;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getOrganizationList() {
      this.loading = true;
      try {
        let ret = await getOrganizationList({
          page: 1,
          pageSize: 999999,
        });
        if (ret.code === 200) {
          this.organizationList = ret.data.list;
          if (this.organizationList.length) {
            this.formState.organizationId = this.organizationList[0].id;
            this.getAllCinemaList(this.formState.organizationId);
          }
          this.onSearch();
        } else {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getAllCinemaList(organizationId) {
      this.cinemaAllList = [];
      this.formState.cinemaId = 0;
      this.cardAllList = [];
      this.formState.cardId = 0;
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined,
      });
      if (ret.code === 200) {
        // if (ret.data.list && ret.data.list.length) {
        // 	this.formState.cinemaId = ret.data.list[0].id;
        // 	this.getCardPolicyList(this.formState.cinemaId);
        // }
        this.cinemaAllList = ret.data.list;
      }
    },
    async getCardPolicyList(cinemaId) {
      this.cardAllList = [];
      this.formState.cardId = 0;
      if (!cinemaId) return;
      let ret = await getCardPolicyList({
        page: 1,
        pageSize: 999999,
        type: 4,
        organizationId: this.formState.organizationId
          ? this.formState.organizationId
          : undefined,
        cinemaId: cinemaId ? cinemaId : undefined,
      });
      if (ret.code === 200) {
        this.cardAllList = ret.data.list;
      }
    },
	async onSyncBatch() {
		this.loading = true;
		let ret = await syncCardInfo({
			cinemaId: this.formState.cinemaId
		});
		this.loading = false;
		if (ret.code === 200) {
			this.$message.success('同步成功!');
			this.getData();
		}
		// this.$confirm({
		// 	title: '警告',
		// 	content: '当前操作为异步操作，相同影院请不要在短时间内多次发起！',
		// 	okText: '立即同步',
		// 	cancelText: '取消操作',
		// 	onOk:async ()=>{
				
		// 	}
		// })
	},
	async onSync(record) {
		this.loading = true;
		let ret = await syncCardInfo({
			id: record.id
		})
		this.loading = false;
		if (ret.code === 200) {
			this.$message.success('同步成功!');
			this.getData();
		}
	},
    onDisabled(item) {
      this.$confirm({
        title: "提示",
        content:
          "确定" + (item.isDisabled === 0 ? "禁用" : "启用") + "此卡吗？",
        onOk: async () => {
          this.loading = true;
          try {
            let ret;
            if (item.isDisabled === 0) {
              ret = await pullOffShelvesCardInfo({
                id: item.id,
              });
            } else {
              ret = await pullOnShelvesCardInfo({
                id: item.id,
              });
            }
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success("操作成功");
              this.getData();
            }
          } catch (e) {
            this.loading = false;
          }
        },
      });
    },
	onUnbind(record) {
		this.$confirm({
			title: '提示',
			content: '确定执行解绑操作吗？',
			onOk: async () => {
				this.loading = true;
				let ret = await cardUnbinding({
					userCardId: record.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('解绑成功！');
					this.getData();
				}
			}
		})
	},
    onMemberCardDetails(record) {
      this.cardInfo = record;
      // this.id = record.id;
      // this.movieId = record.cinemaId;
      this.showRecord = true;
    },
    onModifyCancel() {
      this.$refs.modifyForm.resetFields();
      this.modifyItem = {};
      this.showModal = false;
    },
    onModifyOk() {
      this.$refs.modifyForm.validateFields().then(async () => {
        this.loading = true;
        try {
          let ret = await modifyBalance({
            id: this.modifyItem.id,
            balance: this.modelRef.balance,
          });
          this.loading = false;
          if (ret.code === 200) {
            this.$message.success("余额调整成功");
            this.$refs.modifyForm.resetFields();
            this.showModal = false;
            this.getData();
          }
        } catch (e) {
          this.loading = false;
        }
      });
    },
    onRecord(record) {
      this.id = record.id;
      this.showRecord = true;
    },
    onSelectChange(record, selected) {
      if (selected) {
        if (this.selectedRowKeys.length < 500) {
          this.selectedListData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.$message.warn("最大可选择数据不允许大于500条");
        }
      } else {
        this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
        this.selectedListData = this.selectedListData.filter((item) => {
          return item.id !== record.id;
        });
      }
    },
    onSelectAll(selected) {
      if (selected) {
        let showWain = true;
        this.list.forEach((item) => {
          if (this.selectedRowKeys.length < 500) {
            if (
              this.selectedRowKeys.indexOf(item.id) === -1 &&
              item.status != 7 &&
              item.isNeverExpire != 1 &&
              item.usableTotal
            ) {
              this.selectedListData.push(JSON.parse(JSON.stringify(item)));
              this.selectedRowKeys.push(item.id);
            }
          } else {
            if (showWain) {
              showWain = false;
              this.$message.warn("最大可选择数据不允许大于500条");
            }
          }
        });
      } else {
        this.list.forEach((item) => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            this.selectedListData.splice(
              this.selectedRowKeys.indexOf(item.id),
              1
            );
            this.selectedRowKeys.splice(
              this.selectedRowKeys.indexOf(item.id),
              1
            );
          }
        });
      }
    },
    getCheckboxProps(record) {
      return {
        disabled:
          record.status === 7 ||
          record.isNeverExpire === 1 ||
          !record.usableTotal,
        id: record.id.toString(),
      };
    },
    disabledDate(current) {
      return current && current < this.moment().endOf("day");
    },
  },
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
